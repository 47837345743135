.root {
  // border-bottom: 1px solid var(--page-tab-bar-divider-color);
  background-color: var(--page-tab-bar-background-color);
  display: flex;
  position: sticky;
  top: 64px;
  min-height: 48px;
  padding: 0;
  z-index: 700;
  overflow: auto;
  overflow-y: hidden;

  &.center {
    justify-content: center;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.border {
  width: 100%;
  border-top: 1px solid #ededed;
  position: absolute;
  bottom: 0;
}
