.root {
  text-align: center;
  max-width: 420px;
  margin: 0 auto;
  padding: 6px 0;
}

.link {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: var(--border-radius);
  border-radius: 40px;
  padding: 14px 20px 14px 24px;
  border: 1px solid var(--border-color);

  &:link {
    text-decoration: none;
  }

  &:hover {
    background-color: #f6f6f6;
  }

  &:active {
    background-color: #eee;
  }
}

.dot {
  width: 14px;
  height: 14px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 14px;
  flex-shrink: 0;
  position: relative;
  bottom: -7px;
}

.name {
  font-size: var(--font-size-20px, 20px);
  line-height: var(--line-height-for-20px);
  font-weight: var(--font-weight-bold, bold);
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Hover & active states (ignoring mobile)
:global(.canHover) {
  .link {
    &:hover {
      background-color: #f6f6f6;
    }

    &:active {
      background-color: #eee;
    }
  }
}
