.root {
  display: flex;
  align-items: center;
  min-height: 36px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
    padding-right: 0;
  }

  &.selected {
    .inner {
      font-weight: var(--font-weight-semi-bold);
      border-bottom: 1px solid var(--secondary-color);

      &:link,
      &:visited,
      &:hover,
      &:active {
        color: var(--page-tab-selected-color);
      }

      &.hasLink {
        &:hover {
          color: var(--page-tab-selected-color);
          border-bottom-color: var(--secondary-color);
        }
      }
    }
  }
}

.inner {
  font-size: var(--font-size-16px, 16px);
  font-weight: var(--font-weight-regular);
  display: flex;
  height: 100%;
  align-items: center;
  color: var(--page-tab-color);
  padding: 0 16px;
  z-index: 800;
  border-bottom: 1px solid transparent;
  user-select: none;

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }

  &.hasLink {
    cursor: pointer;

    &:link,
    &:visited {
      color: var(--page-tab-color);
    }

    &:hover {
      text-decoration: none;
      color: var(--page-tab-hover-color);
      border-bottom-color: #bbb;
    }

    &:active {
      color: var(--page-tab-active-color);
    }
  }
}
