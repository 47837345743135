@use 'core/styles/breakpoints';

.coverPhoto {
  height: calc(100vw * 0.25);
  max-height: 262px;
  position: relative;
  overflow: hidden;
  font-size: 0;
  text-align: center;
  max-width: 1048px;
  margin: 0 auto;

  @include breakpoints.abovePx(1280px) {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    height: 320px;
  }
}

.coverPhotoFallback {
  background-color: var(--skeleton-background-color);
  width: 100%;
}

.editProfileButton {
  text-align: center;
  margin-bottom: 20px;
}

.profilePicture {
  position: relative;
  margin: -50px auto 0;
  width: 100px;
  height: 100px;
  min-width: 100px;
  border-radius: 50%;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: #fff !important;
  background-color: var(--skeleton-background-color);

  @include breakpoints.above(mobile) {
    width: 120px;
    height: 120px;
    min-width: 120px;
    margin-top: -60px;
  }
}

.profilePictureImg {
  border-radius: 50%;
}

.title {
  margin-bottom: 30px;
}

.name {
  text-align: center;
  margin: 20px 0 0 0;
  font-weight: var(--font-weight-extra-bold);
  font-size: var(--font-size-24px, 24px);
  line-height: var(--line-height-for-24px);
  letter-spacing: -0.4px;
  padding: 0 20px;

  @include breakpoints.above(mobile) {
    font-size: var(--font-size-30px, 30px);
    line-height: var(--line-height-for-30px);
    letter-spacing: -0.6px;
    margin-top: 26px;
  }
}

.nameSkeleton {
  display: inline !important;
}

.verified {
  margin-left: 8px;
  font-size: 0;
  position: relative;
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-bottom: -2px;

  @include breakpoints.above(mobile) {
    margin-left: 10px;
    width: 26px;
    height: 26px;
    margin-bottom: -3px;
  }
}

.status {
  text-align: center;
  margin-top: 8px;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-16px, 16px);
  line-height: var(--line-height-for-16px);
  letter-spacing: -0.1px;
  padding: 0 20px;
  color: var(--text-lighter-color);

  @include breakpoints.above(mobile) {
    margin-top: 10px;
    font-size: var(--font-size-18px, 18px);
    line-height: var(--line-height-for-18px);
    letter-spacing: -0.2px;
  }
}

.statusSkeleton {
  display: inline !important;
}

.tab {
  display: none;
  max-width: 1024px;
  margin: 40px auto 100px;
  padding: 0 20px;

  &.active {
    display: block;
  }
}

.loading {
  text-align: center;
  margin-top: 60px;
}

.noLinks,
.noAbout {
  text-align: center;
  font-size: var(--font-size-16px, 16px);
  line-height: var(--line-height-for-16px);
}

.creatorApplicantAlert {
  text-align: center;
  margin-bottom: 20px;
}

.creatorApplicantAlertRoot {
  display: inline-block;
  width: auto;
}

.aboutText {
  font-size: var(--font-size-16px, 16px);
  line-height: var(--line-height-for-16px);
  text-align: center;
}
